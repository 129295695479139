<template>
  <page-header-wrapper>
    <un-bind v-if="visible" />
    <div v-else>
      <analyze :analyzeData="analyzeData" />
      <a-card :bordered="false">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="8" :sm="24">
                <a-form-item label="回款状态">
                  <a-select
                    allow-clear
                    :filter-option="filterOption"
                    class="inputLong"
                    placeholder="请选择"
                    v-model="queryParam.payback_status"
                    @change="statusChange"
                  >
                    <a-select-option value="completed">
                      已完成回款
                    </a-select-option>
                    <a-select-option value="uncompleted">
                      未完成回款
                    </a-select-option>
                    <a-select-option value="all">
                      全部
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="部门选择">
                  <a-tree-select
                    allow-clear
                    v-model="queryParam.dept_id"
                    class="inputLong"
                    :replaceFields="departmentFields"
                    :dropdown-style="{ maxHeight: scrollHeight, overflow: 'auto' }"
                    :tree-data="treeData"
                    placeholder="请选择部门"
                    @change="departmentChange"
                  >
                  </a-tree-select>
                </a-form-item>
              </a-col>
              <template v-if="advanced">
                <a-col :md="8" :sm="24">
                  <a-form-item label="回款时间">
                    <a-range-picker v-model="financeTime" @change="financeChange" class="inputLong" />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="认购时间">
                    <a-range-picker v-model="orderTime" @change="orderChange" class="inputLong" />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="签约日期">
                    <a-range-picker v-model="signTime" @change="signChange" class="inputLong" />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="业绩归属日期">
                    <a-range-picker v-model="dealTime" @change="atributeChange" class="inputLong" />
                  </a-form-item>
                </a-col>
              </template>
              <a-col :md="(!advanced && 8) || 24" :sm="24">
                <span
                  class="table-page-search-submitButtons"
                  :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
                >
                  <a-button type="primary" icon="search" @click="reload">查询</a-button>
                  <a-button style="margin-left: 8px" icon="redo" @click="resetReload">重置</a-button>
                  <a @click="toggleAdvanced" style="margin-left: 8px">
                    {{ advanced ? '收起' : '展开' }}
                    <a-icon :type="advanced ? 'up' : 'down'" />
                  </a>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <div class="table-operator">
          <a-button type="primary" icon="download" @click="excelExport">导出</a-button>
        </div>

        <s-table
          ref="table"
          size="default"
          :rowKey="record => record.id"
          :columns="columns"
          :data="loadData"
          :alert="true"
          :totalCount="totalCount"
          :scroll="defaultScroll"
          :rowSelection="rowSelection"
          showPagination="auto"
        >
          <span slot="name" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="room" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="client" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="order" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="sign" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="deal" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="company_commission" slot-scope="text">{{ text | priceFilter }}</span>
          <span slot="distribute_commission" slot-scope="text">{{ text | priceFilter }}</span>
          <span slot="company_net_commission" slot-scope="text">{{ text | priceFilter }}</span>
          <span slot="total_paid_back" slot-scope="text">{{ text | priceFilter }}</span>
          <span slot="type" slot-scope="text">
            <a-badge :text="text | typeTextFilter" :status="text | typeFilter" />
          </span>
          <span slot="finance" slot-scope="text, record">
            <a :class="record.process_form['回款状态'].value === '未完成' ? '' : 'm-gray'" @click="addition(record)">补录信息</a>
          </span>
          <span slot="status" slot-scope="text, record">
            <a-tag :class="updateValidator ? 'myPointer' : ''" :color="text | statusTypeFilter" @click="updateStatus(record)">{{ text | statusFilter }}</a-tag>
          </span>
          <span slot="action" slot-scope="text, record">
            <template>
              <a @click="detail(record)">详情</a> |
              <a style="color: red;" @click="del(record)">删除</a>
            </template>
          </span>
        </s-table>
      </a-card>
    </div>
    <finance-form ref="FinanceForm" @reload="reload" />
    <update-affirm ref="UpdateAffirm" :noticeTitle="noticeTitle" @reload="reload" />
    <detail-drawer :type="3" :id="detailId" v-if="detailId" @close="detailId = null"></detail-drawer>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import FinanceForm from './components/FinanceForm'
import UpdateAffirm from '../components/UpdateAffirm'
import { moxiInstances, moxiDepartmentTree, moxiDel } from '@/api/moxi'
import { financeColumns, operationColumns, statusMap, typeMap } from './js/index'
import UnBind from '../components/UnBind'
import Analyze from './components/Analyze'
import DetailDrawer from '../detail/detail-drawer.vue'

export default {
  components: {
    STable,
    Ellipsis,
    UnBind,
    Analyze,
    FinanceForm,
    DetailDrawer,
    UpdateAffirm
  },
  data() {
    return {
      noticeTitle: '财务回款',
      totalCount: 0,
      page: 0,
      storeKeys: {},
      storeRows: {},
      dealTime: null,
      signTime: null,
      orderTime: null,
      financeTime: null,
      department: null,
      departmentFields: { children: 'children', title: 'name', key: 'dept_id', value: 'dept_id' },
      treeData: [],
      advanced: false,
      queryParam: {
        payback_status: 'all'
      },
      params: {},
      selectedRowKeys: [],
      selectedRows: [],
      analyzeData: {},
      loadData: parameter => {
        this.page = parameter.pageNo
        const param = {
          approve_status: 'completed',
          // invoice_status: 'running',
          sell_type: 'main_seller'
        }
        const params = this.dParamFormat(parameter, param)
        Object.assign(params, this.queryParam)
        this.params = params
        return moxiInstances(this.dingUserId, params).then(res => {
          const result = this.dResFormat(res)
          this.analyzeData = res.data
          this.totalCount = result.totalCount
          return result
        })
      },
      detailId: null
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].color
    },
    typeFilter(type) {
      return typeMap[type].status
    },
    typeTextFilter(type) {
      return typeMap[type].text
    }
  },
  computed: {
    serial() {
      return this.$store.state.env.serial
    },
    dingUserId() {
      return this.$store.state.dingUser.data.user_id
    },
    dingUser() {
      return this.$store.state.dingUser.data
    },
    visible() {
      return Object.keys(this.dingUser).length === 0
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: record => ({
          props: {
            defaultChecked: this.fetchStoreKeys(record.id)
          }
        })
      }
    },
    columns() {
      if (this.btnPermission('member_addition')) {
        return financeColumns
      } else {
        return operationColumns
      }
    },
    additionValidator() {
      return this.btnPermission('member_addition')
    },
    updateValidator() {
      return this.btnPermission('member_update')
    }
  },
  mounted() {
    this.treeFetch()
  },
  methods: {
    treeFetch() {
      moxiDepartmentTree(this.dingUserId, 1).then(res => {
        if (res.success) {
          this.treeData = res.data.children
        }
      }).finally(() => {

      })
    },
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      const param = {
        dealTime: null,
        orderTime: null,
        financeTime: null,
        signTime: null,
        department: null
      }
      Object.assign(this, param)
      this.reload()
    },
    excelExport() {
      const params = {
        authorization: this.dingUserId,
        page_name: '财务回款'
      }
      if (this.storeRows.length !== 0) {
        params.instance_ids = this.fetchMapArr(this.storeRows).map(item => {
          return item.process_instance_id
        }).join(',')
      }
      Object.assign(params, this.queryParam)
      window.location.href = this.requestFormat(this.serial + '/api/v1/dingtalk/sales/instance/export/', params)
    },
    addition(record) {
      if (!this.additionValidator) return
      const validator = record.process_form['回款状态'].value
      if (validator === '未完成') {
        this.$refs.FinanceForm.show(record)
      } else {
        this.notiInfo('补录信息', '已完成, 请不要重复操作。')
      }
    },
    updateStatus(record) {
      if (!this.updateValidator) return
      const status = record.process_form['回款状态'].value
      if (status === '未完成') {
        this.$refs.UpdateAffirm.show(record)
      } else {
        this.notiInfo(this.noticeTitle, '已完成, 请不要重复操作。')
      }
    },
    detail(record) {
      // const query = {
      //   id: record.process_instance_id,
      //   type: 3
      // }
      // this.navTo('/member/finance/detail', query)
      const id = record.process_instance_id;
      this.detailId = id
    },
    del(row) {
      var that = this
      that.$confirm({
        title: '警告',
        content: `真的要删除《 ${row.title} 》吗?`,
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          console.log('OK')
          moxiDel(that.dingUserId, { process_instance_id: row.process_instance_id }).then(res => {
            console.log('moxiDel回调res打印')
            console.log(res)
            that.reload()
            that.$message.info('操作成功')
          })
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.storeKeys[this.page] = selectedRowKeys
      this.storeRows[this.page] = selectedRows
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    fetchStoreKeys(id) {
      const newArr = this.fetchMapArr(this.storeKeys)
      return newArr.includes(id)
    },
    fetchMapArr(map) {
      let key = ''
      const arr = []
      for (key in map) {
        arr.push(map[key])
      }
      const newArr = []
      arr.forEach(item => {
        item.forEach(key => {
          newArr.push(key)
        })
      })
      return newArr
    },
    departmentChange(value) {
      if (value === undefined) {
        delete this.queryParam.dept_id
      }
    },
    statusChange(value) {
      if (value === undefined) {
        delete this.queryParam.payback_status
      }
    },
    financeChange(value) {
      if (value.length === 0) {
        delete this.queryParam.payback_start_time
        delete this.queryParam.payback_stop_time
      } else {
        this.queryParam.payback_start_time = this.dateFormat(value[0]._d, 'YYYY-MM-DD 00:00:00')
        this.queryParam.payback_stop_time = this.dateFormat(value[1]._d, 'YYYY-MM-DD 00:00:00')
      }
    },
    orderChange(value) {
      if (value.length === 0) {
        delete this.queryParam.order_start_time
        delete this.queryParam.order_stop_time
      } else {
        this.queryParam.order_start_time = this.dateFormat(value[0]._d, 'YYYY-MM-DD 00:00:00')
        this.queryParam.order_stop_time = this.dateFormat(value[1]._d, 'YYYY-MM-DD 00:00:00')
      }
    },
    signChange(value) {
      if (value.length === 0) {
        delete this.queryParam.sign_start_time
        delete this.queryParam.sign_stop_time
      } else {
        this.queryParam.sign_start_time = this.dateFormat(value[0]._d, 'YYYY-MM-DD 00:00:00')
        this.queryParam.sign_stop_time = this.dateFormat(value[1]._d, 'YYYY-MM-DD 00:00:00')
      }
    },
    atributeChange(value) {
      if (value.length === 0) {
        delete this.queryParam.attribution_start_time
        delete this.queryParam.attribution_stop_time
      } else {
        this.queryParam.attribution_start_time = this.dateFormat(value[0]._d, 'YYYY-MM-DD 00:00:00')
        this.queryParam.attribution_stop_time = this.dateFormat(value[1]._d, 'YYYY-MM-DD 00:00:00')
      }
    }
  }
}
</script>
