<template>
  <a-row :gutter="24">
    <a-col :sm="sm" :md="md" :xl="xl" :style="{ marginBottom: '24px' }">
      <chart-card :loading="false" title="公司佣金总额" :total="preNumberFormat(analyzeData.company_commission)">
        <a-tooltip title="公司佣金总额" slot="action">
          <a-icon type="info-circle-o" />
        </a-tooltip>
        <div>
          <trend flag="up" style="margin-right: 16px;">
            <span slot="term">周同比</span>
            12%
          </trend>
          <trend flag="down">
            <span slot="term">日同比</span>
            11%
          </trend>
        </div>
        <template slot="footer">日均销售额<span>￥ 234.56</span> 万</template>
      </chart-card>
    </a-col>
    <a-col :sm="sm" :md="md" :xl="xl" :style="{ marginBottom: '24px' }">
      <chart-card :loading="false" title="对外拆佣总额" :total="preNumberFormat(analyzeData.distribute_commission)">
        <a-tooltip title="对外拆佣总额" slot="action">
          <a-icon type="info-circle-o" />
        </a-tooltip>
        <div>
          <mini-bar />
        </div>
        <template slot="footer">转化率 <span>60%</span></template>
      </chart-card>
    </a-col>
    <a-col :sm="sm" :md="md" :xl="4" :style="{ marginBottom: '24px' }">
      <chart-card :loading="false" title="公司净佣总额" :total="preNumberFormat(analyzeData.company_net_commission)">
        <a-tooltip title="公司净佣总额" slot="action">
          <a-icon type="info-circle-o" />
        </a-tooltip>
        <div>
          <mini-area />
        </div>
        <template slot="footer">日均成交<span> {{ '1234' | NumberFormat }}</span></template>
      </chart-card>
    </a-col>
    <a-col :sm="sm" :md="md" :xl="xl" :style="{ marginBottom: '24px' }">
      <chart-card :loading="false" title="已回款金额总额" :total="preNumberFormat(analyzeData.total_paid_back)">
        <a-tooltip title="已回款金额总额" slot="action">
          <a-icon type="info-circle-o" />
        </a-tooltip>
        <div>
          <mini-progress color="rgb(19, 194, 194)" :target="80" :percentage="78" height="8px" />
        </div>
        <template slot="footer">
          <trend flag="down" style="margin-right: 16px;">
            <span slot="term">同周比</span>
            12%
          </trend>
          <trend flag="up">
            <span slot="term">日环比</span>
            80%
          </trend>
        </template>
      </chart-card>
    </a-col>
    <a-col :sm="sm" :md="md" :xl="xl" :style="{ marginBottom: '24px' }">
      <chart-card :loading="false" title="已开票金额总额" :total="preNumberFormat(analyzeData.total_invoiced)">
        <a-tooltip title="已开票金额总额" slot="action">
          <a-icon type="info-circle-o" />
        </a-tooltip>
        <div>
          <mini-progress color="rgb(19, 194, 194)" :target="80" :percentage="78" height="8px" />
        </div>
        <template slot="footer">
          <trend flag="down" style="margin-right: 16px;">
            <span slot="term">同周比</span>
            12%
          </trend>
          <trend flag="up">
            <span slot="term">日环比</span>
            80%
          </trend>
        </template>
      </chart-card>
    </a-col>
  </a-row>
</template>

<script>
import { ChartCard, Bar, Trend, MiniArea, MiniProgress, MiniBar } from '@/components'

export default {
  components: {
    ChartCard,
    Bar,
    Trend,
    MiniArea,
    MiniProgress,
    MiniBar
  },
  props: {
    analyzeData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
      sm: 24,
      md: 12,
      xl: 5
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="less" scoped>
  /deep/ .chart-card-content,
  /deep/ .chart-card-footer {
    display: none;
  }
</style>
